<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard>
        <CCardHeader> Property Type id: {{ $route.params.id }} </CCardHeader>
        <CCardBody>
          <CDataTable striped small fixed :items="items" :fields="fields">
            <template slot="value" slot-scope="data">
              <strong>{{ data.item.value }}</strong>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CButton color="secondary" @click="goBack">Back</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'PropertyType',
  data: () => {
    return {
      items: [],
      fields: [{ key: 'key' }, { key: 'value' }]
    }
  },
  methods: {
    /*getPropertyTypeData(id) {
      const propertyType = propertyTypeTypesData.find((propertyType, index) => index + 1 == id)
      const propertyTypeDetails = propertyType ? Object.entries(propertyType) : [['id', 'Not found']]
      return propertyTypeDetails.map(([key, value]) => {
        return { key, value }
      })
    },*/
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    let self = this
    this.$http
      .get('admin/property_types/' + this.$route.params.id)
      .then(response => {
        const items = Object.entries(response.data.property_type)
        self.items = items.map(([key, value]) => {
          return { key: key, value: value }
        })
      })
      .catch(error => {
        console.error(error)
        self.$router.push({ path: '/login' })
      })
  }
}
</script>
